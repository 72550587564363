/* 페이지에서 이렇게 선언하면 로그인만 접근하게
definePageMeta({
  middleware: 'auth'
})
*/
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { useVfm } from 'vue-final-modal'

export default defineNuxtRouteMiddleware((to, from) => {
  const vfm = useVfm()
  vfm.closeAll()
  const { isLoggedIn } = useMemberSessionStore()
  console.log('isLoggedIn', isLoggedIn)

  if (!isLoggedIn) {
    return navigateTo(`/auth/sign-in?redirectUrl=${to.fullPath}`)
  }
})
